import BN from "bn.js";
import cn from "classnames";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { Contract } from "web3-eth-contract";
import StakeInput from "./supportComponents/StakeInput/StakeInput";
import StakeModal from "./supportComponents/StakeModal/StakeModal";
import WithdrawModal from "./supportComponents/WithdrawModal/WithdrawModal";

import ADDRESSES from "../../constants/address";
import ERC20 from "../../constants/ERC20.json";
import STEP_EX_STAKING from "../../constants/StepExStaking.json";
import { beautifyTokenBalance, formattedNum, fromHRToBN, toHRNumberFloat } from "../../utils/bigNumber";
import { CurrentEpochDataType, EpochDataType, StakeContractDataType, StakeDataType } from "../../utils/types";

import { isTechnicalWorks } from "../../constants/texts";
import useContextSelector from "../../contexts/contextSelector";
import useMulticall from "../../hooks/useMulticall";
import { useSPEXInfo } from "../../hooks/useSpexInfo";
import useWeb3Provider from "../../hooks/useWeb3Provider";
import SpexInfo from "../spexInfo";
import ZeroInflationPage from "../ZeroInflationPage/ZeroInflationPage";
import "./HomePage.scss";
import "./HomePageMobile.scss";

const MAX_VALUE = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

const HomePage = () => {
    const [stakeValue, setStakeValue] = useState<number | undefined>(undefined);
    const [isHowItWorksModalVisible, setIsHowItWorksModalVisible] = useState(false);
    const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState(false);
    const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [msUntilEvent, setMsUntilEvent] = useState<number | undefined>(undefined);
    const spexData = useSPEXInfo();

    const [stakers, setStakers] = useState<StakeDataType[]>([]);
    const [stakerReward, setStakerReward] = useState(new BN(0));
    const [epoch, setEpoch] = useState<CurrentEpochDataType | undefined>(undefined);
    const [currentUntilEvent, setCurrentUntilEvent] = useState<number | undefined>(undefined);

    const [tokenContract, setTokenContract] = useState<Contract | undefined>(undefined);
    const [stepStakeContract, setStepStakeContract] = useState<Contract | undefined>(undefined);
    const [stepCalcContract, setStepCalcContract] = useState<Contract | undefined>(undefined);

    const [fitfiBalance, setFitfiBalance] = useState("");
    const [fitfiDecimals, setFitfiDecimals] = useState("");

    const [unstakedBalance, setUnstakedBalance] = useState("");

    const [stepBalance, setTokenBalance] = useState("");
    const [stepDecimals, setTokenDecimals] = useState(0);

    // eslint-disable-next-line no-undef
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);

    const { web3Provider: web3, currentAddress, onConnect } = useContextSelector((state) => state.web3Provider);
    const multicall = useMulticall();
    // const currentAddress = undefined;

    useEffect(() => {
        if (web3) {
            setTokenContract(new web3.eth.Contract(ERC20 as any, ADDRESSES.MOCK_TOKEN));
            setStepStakeContract(new web3.eth.Contract(STEP_EX_STAKING as any, ADDRESSES.STEP_EX_STAKING));
        }
    }, [web3]);

    useEffect(() => {
        if (msUntilEvent) {
            if (intervalId) {
                setIntervalId(undefined);
                clearInterval(intervalId);
                setCurrentUntilEvent(msUntilEvent);

                const newIntervalId = setInterval(() => {
                    setCurrentUntilEvent((v) => (v ? Math.max(v - 1000, 0) : msUntilEvent));
                }, 1000);

                setIntervalId(newIntervalId);
            } else {
                const newIntervalId = setInterval(() => {
                    setCurrentUntilEvent((v) => (v ? Math.max(v - 1000, 0) : msUntilEvent));
                }, 1000);

                setIntervalId(newIntervalId);
            }
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [msUntilEvent]);

    const updateFitfiInfo = async () => {
        if (tokenContract) {
            setFitfiBalance(await tokenContract.methods.balanceOf(currentAddress).call());
            setFitfiDecimals(await tokenContract.methods.decimals().call());
        }
    };

    const updateInformation = async () => {
        if (tokenContract && stepStakeContract && currentAddress) {
            updateFitfiInfo();

            const newTokenBalance = await stepStakeContract.methods.balanceOf(currentAddress).call();
            setTokenBalance(newTokenBalance);

            const unstakedResult = await stepStakeContract.methods.balanceOfUnstaked(currentAddress).call();
            setUnstakedBalance(unstakedResult);

            const newDecimals = await stepStakeContract.methods.decimals().call();
            setTokenDecimals(newDecimals);

            const stakerStakeCount = await stepStakeContract.methods.stakerStakeCount(currentAddress).call();
            const newStakers: StakeDataType[] = [];
            const stakingContract = new ethers.Contract(ADDRESSES.STEP_EX_STAKING, STEP_EX_STAKING);

            const encodeResult = new Array(+stakerStakeCount).fill(1).map((item: any, index: any) => {
                return {
                    address: ADDRESSES.STEP_EX_STAKING,
                    callData: stakingContract.interface.encodeFunctionData("stakers", [currentAddress, index]),
                };
            });

            const callData = encodeResult.map((obj) => [obj.address, obj.callData]);

            try {
                const [_, returnData] = await multicall.aggregate(callData);
                const result = returnData.reduce((response: any[], aggregateItemResult: any, i: number) => {
                    const data = stakingContract.interface.decodeFunctionResult("stakers", aggregateItemResult);
                    response[i] = data;
                    return response;
                }, []);

                const withdrawalsCallData = result.map((elem: any, index: number) => [
                    ADDRESSES.STEP_EX_STAKING,
                    stakingContract.interface.encodeFunctionData("withdrawals", [currentAddress, index]),
                ]);

                const [o, withdrawals] = await multicall.aggregate(withdrawalsCallData);
                const callRewardDataResult = withdrawals.map((call: any, index: any) => {
                    return stakingContract.interface.decodeFunctionResult("withdrawals", call);
                });

                for (let i = 0; i < result.length; ++i) {
                    const withdrawStatus = callRewardDataResult[i];

                    const unstaked = result[i][0];
                    if (!withdrawStatus.withdrawn) {
                        if (unstaked && Number(withdrawStatus.withdrawalTimestamp) === 0) {
                            // eslint-disable-next-line no-continue
                            continue;
                        }
                        const newStaker = {
                            ...result[i],
                            id: i,
                            amount: beautifyTokenBalance(String(result[i].amount), newDecimals),
                            withdrawTimestamp: withdrawStatus.withdrawalTimestamp,
                        };
                        newStakers.push(newStaker);
                    }
                }
                setStakers(newStakers);
            } catch (error) {
                console.log("multicall error", error);
            }
        }
    };
    const checkAllowance = async () => {
        if (tokenContract && currentAddress) {
            const allowance = await tokenContract.methods.allowance(currentAddress, ADDRESSES.STEP_EX_STAKING).call();
            if (allowance > 0) {
                setIsApproved(true);
            }
        }
    };
    useEffect(() => {
        if (tokenContract && stepStakeContract && currentAddress) {
            checkAllowance();
            updateInformation();
        }
    }, [tokenContract, currentAddress]);

    useEffect(() => {
        const updateIntervalId = setInterval(async () => {
            await updateInformation();
        }, 5000);

        return () => {
            clearInterval(updateIntervalId);
        };
    }, [tokenContract, currentAddress]);

    const updateInformationOnVisibilityChange = (event: Event) => {
        if (!document.hidden) {
            updateInformation();
        }
    };

    useEffect(() => {
        document.addEventListener("visibilitychange", updateInformationOnVisibilityChange);
    }, [tokenContract, currentAddress]);

    const handleStake = async () => {
        if (stepStakeContract && stepDecimals && stakeValue && stakeValue >= 1) {
            setIsLoading(true);
            try {
                const amountBN = fromHRToBN(stakeValue, stepDecimals).toString();
                await stepStakeContract?.methods.stake(amountBN).send({ from: currentAddress });
                setIsLoading(false);
                setStakeValue(0);
                await updateInformation();
            } catch (e) {
                console.error(e);
                setIsLoading(false);
            }
        }
    };
    const handleApprove = async () => {
        setIsLoading(true);

        try {
            await tokenContract?.methods.approve(ADDRESSES.STEP_EX_STAKING, MAX_VALUE).send({ from: currentAddress });
            setIsApproved(true);
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };

    const handleStakeValueChange = (value?: number) => {
        setStakeValue(value);
    };

    const openWithdrawModal = () => {
        setIsWithdrawModalVisible(true);
    };
    const openHowItWorksModal = () => {
        setIsHowItWorksModalVisible(true);
    };

    const closeWithdrawModal = () => {
        setIsWithdrawModalVisible(false);
    };

    const openStakeModal = () => {
        setIsStakeModalVisible(true);
    };
    const closeStakeModal = () => {
        setIsStakeModalVisible(false);
    };

    const renderMobileButtons = () => {
        return (
            <>
                {isApproved ? (
                    <div
                        className={cn("home-page-mobile__stake-button", {
                            "home-page-mobile__stake-button--disabled":
                                isLoading || isTechnicalWorks || !stakeValue || !currentAddress || stakeValue < 1,
                        })}
                        onClick={handleStake}
                    >
                        {isLoading ? "Loading..." : "Stake"}
                    </div>
                ) : (
                    <div
                        className={cn("home-page-mobile__stake-button", {
                            "home-page-mobile__stake-button--disabled":
                                isLoading || !currentAddress || isTechnicalWorks,
                        })}
                        onClick={handleApprove}
                    >
                        {isLoading ? "Loading..." : "Approve"}
                    </div>
                )}
            </>
        );
    };

    const homepageMobile = () => {
        // const marginTop = Math.max(height - 670, 0);
        const marginTop = 0;

        return (
            <div className="home-page-mobile">
                {spexData ? (
                    <div className="info_raw">
                        <p className="info_title">
                            market cap: <span>{formattedNum(spexData?.marketCup, true)}</span>
                        </p>
                        <p className="info_title">
                            spex price: <span>{formattedNum(spexData?.price, true)}</span>
                        </p>
                    </div>
                ) : null}
                <div className="home-page-mobile__section">
                    <div className="home-page-mobile__title-text">Stake your SPEX and get rewards from Treasury</div>
                </div>
                {spexData && <SpexInfo spexData={spexData} />}
                <StakeInput
                    epoch={epoch}
                    stepStakeContract={stepStakeContract}
                    decimals={stepDecimals}
                    balance={toHRNumberFloat(new BN(fitfiBalance), +fitfiDecimals)}
                    value={stakeValue}
                    staked={toHRNumberFloat(new BN(stepBalance), stepDecimals)}
                    unstaked={toHRNumberFloat(new BN(unstakedBalance), stepDecimals)}
                    onChange={handleStakeValueChange}
                    openWithdrawModal={openWithdrawModal}
                    openHowItWorksModal={openHowItWorksModal}
                />
                {/* <div className="home-page-mobile__section">
                    <div className="home-page-mobile__title">
                        {toHRNumberFloat(stakerReward, stepDecimals).toLocaleString("en-us")} DT #
                        {epoch ? +epoch.epochIndex + 1 : 0} (
                        <span className="home-page__title__old" onClick={openSnapshotModal}>
                            SNAPSHOT #{epoch ? +epoch.epochIndex : 0}
                        </span>
                        )
                    </div>
                    <div className="home-page-mobile__title-text">
                        <div>+{rewardsNumber} PER DAY</div>
                        <div>
                            {currentUntilEvent ? getTimeLeft(Math.floor(currentUntilEvent / 1000)) : 0}
                            <br />
                            UNTIL SNAPSHOT
                        </div>
                    </div>
                </div> */}
                <div className="home-page-mobile__section" style={{ marginTop }}>
                    {renderMobileButtons()}
                </div>
                <WithdrawModal
                    epochIndex={epoch?.epochIndex ? +epoch.epochIndex : undefined}
                    decimals={stepDecimals}
                    onUpdate={updateInformation}
                    currentAddress={currentAddress}
                    stepContract={stepStakeContract}
                    stakers={stakers}
                    visible={isWithdrawModalVisible}
                    onClose={closeWithdrawModal}
                />
                <StakeModal
                    epoch={epoch}
                    visible={isStakeModalVisible}
                    stepStakeContract={stepStakeContract}
                    decimals={stepDecimals}
                    onClose={closeStakeModal}
                    isLoading={isLoading}
                    balance={toHRNumberFloat(new BN(fitfiBalance), +fitfiDecimals)}
                    staked={toHRNumberFloat(new BN(stepBalance), stepDecimals)}
                    onChange={handleStakeValueChange}
                    onStake={handleStake}
                    value={stakeValue}
                />
            </div>
        );
    };

    // if (width <= 600) {
    //     const marginTop = Math.max(height - 670, 0);

    //     return (
    //         <div className="home-page-mobile">
    //             <div className="home-page-mobile__section">
    //                 <div className="home-page-mobile__title">
    //                     {toHRNumberFloat(stakerReward, stepDecimals).toLocaleString("en-us")} DT #
    //                     {epoch ? +epoch.epochIndex + 1 : 0} (
    //                     <span className="home-page__title__old" onClick={openSnapshotModal}>
    //                         SNAPSHOT #{epoch ? +epoch.epochIndex : 0}
    //                     </span>
    //                     )
    //                 </div>
    //                 <div className="home-page-mobile__title-text">
    //                     <div>+{rewardsNumber} PER DAY</div>
    //                     <div>
    //                         {currentUntilEvent ? getTimeLeft(Math.floor(currentUntilEvent / 1000)) : 0}
    //                         <br />
    //                         UNTIL SNAPSHOT
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="home-page-mobile__section" style={{ marginTop }}>
    //                 {renderMobileButtons()}
    //             </div>
    //             <WithdrawModal
    //                 epochIndex={epoch?.epochIndex ? +epoch.epochIndex : undefined}
    //                 decimals={stepDecimals}
    //                 onUpdate={updateInformation}
    //                 currentAddress={currentAddress}
    //                 stepContract={stepStakeContract}
    //                 stakers={stakers}
    //                 visible={isWithdrawModalVisible}
    //                 onClose={closeWithdrawModal}
    //             />
    //             <StakeModal
    //                 epoch={epoch}
    //                 stepCalcContract={stepCalcContract}
    //                 visible={isStakeModalVisible}
    //                 stepStakeContract={stepStakeContract}
    //                 decimals={stepDecimals}
    //                 onClose={closeStakeModal}
    //                 isLoading={isLoading}
    //                 balance={toHRNumberFloat(new BN(fitfiBalance), +fitfiDecimals)}
    //                 staked={toHRNumberFloat(new BN(stepBalance), stepDecimals)}
    //                 onChange={handleStakeValueChange}
    //                 onStake={handleStake}
    //                 value={stakeValue}
    //             />
    //             <HowItWorksModal visible={isHowItWorksModalVisible} onClose={closeHowItWorksModal} />
    //             {renderSnapshotModals()}
    //         </div>
    //     );
    // }
    return (
        <>
            <div className="home-page">
                {spexData ? (
                    <div className="info_raw">
                        <p className="info_title">
                            market cap: <span>{formattedNum(spexData?.marketCup, true)}</span>
                        </p>
                        <p className="info_title">
                            spex price: <span>{formattedNum(spexData?.price, true)}</span>
                        </p>
                    </div>
                ) : null}
                <div className="home-page__title-text">Stake your SPEX and get rewards from Treasury</div>
                {spexData && <SpexInfo spexData={spexData} />}
                <div className="home-page__input-container">
                    <StakeInput
                        epoch={epoch}
                        stepStakeContract={stepStakeContract}
                        decimals={stepDecimals}
                        balance={toHRNumberFloat(new BN(fitfiBalance), +fitfiDecimals)}
                        value={stakeValue}
                        staked={toHRNumberFloat(new BN(stepBalance), stepDecimals)}
                        unstaked={toHRNumberFloat(new BN(unstakedBalance), stepDecimals)}
                        onChange={handleStakeValueChange}
                        openWithdrawModal={openWithdrawModal}
                        openHowItWorksModal={openHowItWorksModal}
                    />
                    {isApproved ? (
                        <div
                            className={cn("home-page__stake-button", {
                                "home-page__stake-button--disabled":
                                    isLoading ||
                                    isTechnicalWorks ||
                                    !currentAddress ||
                                    !stakeValue ||
                                    stakeValue > toHRNumberFloat(new BN(fitfiBalance), +fitfiDecimals) ||
                                    stakeValue < 1,
                            })}
                            onClick={handleStake}
                        >
                            {isLoading ? "Loading..." : "Stake"}
                        </div>
                    ) : (
                        <div
                            className={cn("home-page__stake-button", {
                                "home-page__stake-button--disabled": isLoading || !currentAddress || isTechnicalWorks,
                            })}
                            onClick={handleApprove}
                        >
                            {isLoading ? "Loading..." : "Approve"}
                        </div>
                    )}
                </div>

                <WithdrawModal
                    epochIndex={epoch?.epochIndex ? +epoch.epochIndex : undefined}
                    decimals={stepDecimals}
                    onUpdate={updateInformation}
                    currentAddress={currentAddress}
                    stepContract={stepStakeContract}
                    stakers={stakers}
                    visible={isWithdrawModalVisible}
                    onClose={closeWithdrawModal}
                />
            </div>
            {homepageMobile()}
            <ZeroInflationPage />
        </>
    );
};

export default HomePage;
