import gql from "graphql-tag";

export const BUNDLE_ID = "1";

const TokenFields = `
  fragment TokenFields on Token {
    id
    name
    symbol
    derivedFITFI
    tradeVolume
    tradeVolumeUSD
    untrackedVolumeUSD
    totalLiquidity
    txCount
  }
`;

export const TOKEN_DATA = (tokenAddress: string, block?: number) => {
    const queryString = `
    ${TokenFields}
    query tokens {
      tokens(${block ? `block : {number: ${block}}` : ``} where: {id:"${tokenAddress}"}) {
        ...TokenFields
      }
      pairs0: pairs(where: {token0: "${tokenAddress}"}, first: 50, orderBy: reserveUSD, orderDirection: desc){
        id
      }
      pairs1: pairs(where: {token1: "${tokenAddress}"}, first: 50, orderBy: reserveUSD, orderDirection: desc){
        id
      }
    }
  `;
    return gql(queryString);
};

export const GET_BLOCK = gql`
    query blocks($timestampFrom: Int!, $timestampTo: Int!) {
        blocks(
            first: 1
            orderBy: timestamp
            orderDirection: asc
            where: { timestamp_gt: $timestampFrom, timestamp_lt: $timestampTo }
        ) {
            id
            number
            timestamp
        }
    }
`;

export const ETH_PRICE = (block?: number) => {
    const queryString = block
        ? `
    query bundles {
      bundles(where: { id: ${BUNDLE_ID} } block: {number: ${block}}) {
        id
        fitfiPrice
      }
    }
  `
        : ` query bundles {
      bundles(where: { id: ${BUNDLE_ID} }) {
        id
        fitfiPrice
      }
    }
  `;
    return gql(queryString);
};

export const SPEX_POL = gql`
    {
        pairs(where: { id: "0x94449d70daed63004df6802c16f9e325151b7462" }) {
            volumeUSD
        }
    }
`;

export const STEPEX_FEES = gql`
    {
        stepExDayDatas {
            dailyVolumeUSD
        }
    }
`;

export const SPEX_BURNED = gql`
    {
        tokenDailySnapshots {
            dailyBurnAmount
        }
    }
`;
