import BN from "bn.js";
import React from "react";

import DataContainer from "../../../DataContainer/DataContainer";

import useDimensions from "../../../../hooks/useDimensions";
import { toHRNumberFloat } from "../../../../utils/bigNumber";
import { StakeDataType } from "../../../../utils/types";
import "./WithdrawCardBack.scss";

interface WithdrawCardProps {
    maxBPS: number;
    onAgree: () => void;
    onCancel: () => void;
    stakerData?: StakeDataType;
    epochIndex?: number;
    decimals: number;
}

const WithdrawCardBack = ({ onAgree, onCancel, stakerData, decimals, maxBPS, epochIndex }: WithdrawCardProps) => {
    const { penaltyBP, reward, penaltyDays } = stakerData ?? {};
    const { width } = useDimensions();
    const dt = reward ? toHRNumberFloat(reward, decimals).toLocaleString("en-us") : 0;
    const penalty = penaltyBP && maxBPS ? (+penaltyBP / maxBPS) * 100 : 0;

    const unstake = () => {
        onAgree();
    };

    return (
        <DataContainer className="withdraw-card-back" padding={width > 600 ? 24 : 18}>
            <div className="withdraw-card-back__warning">Warning</div>
            <div className="withdraw-card-back__description">
                {/* After withdrawal you will lose accumulated DT on this deposit in the amount of {dt} DT #
                {(epochIndex ?? 0) + 1}. */}
                For the early withdrawal (up to {penaltyDays} days) will be charged a fee of {penalty}% of SPEX.
            </div>

            <div className="withdraw-card-back__actions">
                <div className="withdraw-card-back__actions__cancel" onClick={onCancel}>
                    Cancel
                </div>
                <div className="withdraw-card-back__actions__agree" onClick={unstake}>
                    unstake
                </div>
            </div>
        </DataContainer>
    );
};

export default WithdrawCardBack;
