import BN from "bn.js";
import Numeral from "numeral";

export const beautifyTokenBalance = (balance: string, decimals: number, fraction = 5) => {
    const exp = 10 ** fraction;

    return (+balance.slice(0, -decimals + fraction) / exp).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: fraction,
    });
};

export const fromHRToBN = (n: number, decimals: number) => {
    const MAX_SMALL_DECIMAL = 6;
    if (decimals <= MAX_SMALL_DECIMAL) {
        return new BN(10).pow(new BN(decimals)).muln(n);
    }

    const multiplierSmall = new BN(10).pow(new BN(MAX_SMALL_DECIMAL));
    const multiplierMain = new BN(10).pow(new BN(decimals - MAX_SMALL_DECIMAL));

    return multiplierSmall.mul(new BN(n)).mul(multiplierMain);
};

export const toHRNumber = (bn: BN, decimal: number) => {
    if (!decimal) {
        return 0;
    }
    return bn.div(new BN(10).pow(new BN(decimal))).toNumber();
};
export const toHRNumberFloat = (bn: BN, decimal = 0) => toHRNumber(bn.muln(1000), decimal) / 1000;

export const ceilNumber = (n: number) => Math.ceil(n * 100) / 100;

export const floorNumber = (n: number) => Math.floor(n * 100) / 100;

export const formatDollarAmount = (num: number, digits: number) => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });
    return formatter.format(num); // 977.153,4 $
};
export const toK = (num: string) => {
    return Numeral(num).format("0.[00]a");
};
export const formattedNum = (number: any, usd = false, acceptNegatives = false) => {
    if (Number.isNaN(number) || number === "" || number === undefined) {
        return usd ? "$0" : 0;
    }
    const num = parseFloat(number);

    if (num > 500000000) {
        return (usd ? "$" : "") + toK(num.toFixed(0));
    }

    if (num === 0) {
        if (usd) {
            return "$0";
        }
        return 0;
    }

    if (num < 0.0001 && num > 0) {
        return usd ? "< $0.0001" : "< 0.0001";
    }

    if (num > 1000) {
        return usd ? formatDollarAmount(num, 0) : Number(parseFloat(String(num)).toFixed(0)).toLocaleString("en-US");
    }

    if (usd) {
        if (num < 0.1) {
            return formatDollarAmount(num, 4);
        }
        return formatDollarAmount(num, 2);
    }

    return Number(parseFloat(String(num)).toFixed(2)).toString();
};
