import React, { FC } from "react";
import DataContainer from "../../DataContainer/DataContainer";
import { poolsDuration, secondsInDays } from "../constants/constants";
import { IHistoryData } from "../constants/types";

const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

interface IHistoryComponent {
    historyData: IHistoryData[][];
}

const StakeHistory: FC<IHistoryComponent> = ({ historyData }) => {
    const computePoolRemaming = (stakeDate: Date, currentPool: number) => {
        const poolIndex = poolsDuration.findIndex((item) => item === currentPool);
        const timeFromLastStake = new Date().valueOf() - stakeDate.valueOf();

        const timeToNextPool = secondsInDays * 1000 * poolsDuration[poolIndex + 1] - timeFromLastStake;

        const remainingDays = Math.floor(timeToNextPool / (1000 * 60 * 60 * 24));
        const time = {
            days: remainingDays < 10 ? `0${remainingDays}` : remainingDays,
        };

        return time;
    };

    if (!historyData.flat(Infinity).length) {
        return null;
    }

    return (
        <div className="history">
            <div className="history__head-content">
                <h1 className="history__head-content__headline">Stake history</h1>
            </div>
            <div className="history__container">
                {!!historyData.length &&
                    historyData.map((pool, poolIdx) => {
                        if (!pool.length) {
                            return null;
                        }
                        return pool.map((item) => {
                            return (
                                <DataContainer
                                    padding={24}
                                    className="history__item history_card"
                                    key={item.stakedTimestamp.valueOf()}
                                >
                                    <div className="history__item__content">
                                        <div className="history__item__content__header history_card">
                                            <div className="history__item__content__header__period history_card">
                                                <h3>
                                                    {item.stakedTimestamp.getDate()}{" "}
                                                    {monthNames[item.stakedTimestamp.getMonth()]}:
                                                </h3>
                                            </div>
                                        </div>

                                        <div className="history__item__content__footer">
                                            <div className="history__item__content__footer__user-data history_card">
                                                <h1>{item.amount.toLocaleString("en")} SPEX</h1>
                                                {item.currentPool > 0 && <h3>{item.currentPool}+ days</h3>}
                                                {item.currentPool < 12 && (
                                                    <h3>
                                                        {
                                                            computePoolRemaming(item.stakedTimestamp, item.currentPool)
                                                                .days
                                                        }{" "}
                                                        days till{" "}
                                                        {
                                                            poolsDuration[
                                                                poolsDuration.findIndex(
                                                                    (months) => months === item.currentPool
                                                                ) + 1
                                                            ]
                                                        }
                                                        + days pool
                                                    </h3>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </DataContainer>
                            );
                        });
                    })}
            </div>
        </div>
    );
};

export default StakeHistory;
