import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import cn from "classnames";

import "./StakeModal.scss";
import BN from "bn.js";
import { Contract } from "web3-eth-contract";
import useDimensions from "../../../../hooks/useDimensions";
import { ceilNumber, fromHRToBN, toHRNumberFloat } from "../../../../utils/bigNumber";
import { getRewardPerDay } from "../../../../utils/time";
import { CurrentEpochDataType } from "../../../../utils/types";
import { isTechnicalWorks, showDisclaimer } from "../../../../constants/texts";

interface StakeModalrProps {
    visible: boolean;
    onClose: () => void;
    isLoading: boolean;
    balance: number;
    staked: string | number;
    value?: number;
    onChange: (value?: number) => void;
    onStake: () => void;
    stepStakeContract?: Contract;
    decimals: number;
    epoch?: CurrentEpochDataType;
}

const StakeModal = ({
    visible,
    onClose,
    balance,
    staked,
    value,
    onChange,
    onStake,
    isLoading,
    stepStakeContract,
    epoch,
    decimals,
}: StakeModalrProps) => {
    const { height } = useDimensions();
    const [income, setIncome] = useState(0);
    const [inputState, setInputState] = useState<string | undefined>();

    const calcShares = async () => {
        if (value && stepStakeContract) {
            const shares = await stepStakeContract.methods.calculateShares(fromHRToBN(value, decimals)).call();
            const newIncome = 0;
            try {
                const res = toHRNumberFloat(getRewardPerDay(new BN(newIncome)), decimals);
                const ceilRes = ceilNumber(res);
                setIncome(ceilRes);
            } catch (e) {
                setIncome(0);
            }
        }
    };

    useEffect(() => {
        if (value && stepStakeContract) {
            calcShares();
        } else {
            setIncome(0);
        }
    }, [value]);

    const setInputStateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;

        if (!inputValue || +inputValue < 1) {
            setInputState(``);
        } else {
            setInputState(`${Math.floor(+inputValue)}`);
        }
    };

    // const handleStakeValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     if (e.target.value === undefined) {
    //         onChange(undefined);
    //     } else {
    //         onChange(+e.target.value);
    //     }
    // };

    const handleMaxClick = () => {
        if (balance) {
            setInputState(`${Math.floor(balance)}`);
        }
    };

    useEffect(() => {
        if (inputState) {
            onChange(+inputState);
        } else {
            onChange(undefined);
        }
    }, [inputState]);

    const marginTop = Math.max(height - 400, 0);

    return (
        <Modal
            isOpen={visible}
            onRequestClose={onClose}
            className="stake-modal-container"
            overlayClassName="stake-modal__overlay"
        >
            <div className={cn("stake-modal", { activeDisclaimer: showDisclaimer })}>
                <div
                    className={cn("stake-modal__close", {
                        "stake-modal__activeDisclaimer": showDisclaimer,
                    })}
                    onClick={onClose}
                >
                    CLOSE
                </div>
                <div className="stake-modal__title">How much do you want to stake:</div>
                <input
                    type="number"
                    className="stake-modal__input"
                    placeholder="0"
                    value={inputState}
                    onChange={setInputStateHandler}
                />
                {!!income && (
                    <div className="stake-modal__income">+{income.toLocaleString("en-us")} DROP TICKETS PER DAY</div>
                )}

                <div className="stake-modal__info-data" style={{ marginTop }}>
                    <div className="stake-modal__info-data__max" onClick={handleMaxClick}>
                        Max {Math.floor(balance).toLocaleString("en-us")} SPEX
                    </div>
                    <div>Staked by You: {staked.toLocaleString("en-us")} SPEX</div>

                    <div
                        className={cn("stake-modal__stake-button", {
                            "stake-modal__stake-button--disabled":
                                isLoading || isTechnicalWorks || !value || value > balance || value < 1,
                        })}
                        onClick={onStake}
                    >
                        {isLoading ? "Loading..." : "Stake"}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default StakeModal;
