import React, { useEffect, useState } from "react";
import countdownTimer from "../../../utils/countdownTimer";

// const milisecondsInHour = 1000 * 60 * 60;
const targetTimestamp = 1666353600;
// const time = targetTimestamp - Math.floor(+new Date() / 1000);

const BeforeYieldTimer = () => {
    const [timer, setTimer] = useState<string | null>("00:00:00:00");
    // const [dateForTimer, setDateForTimer] = useState(time);
    useEffect(() => {
        const timerInterval = setInterval(() => setTimer(countdownTimer(targetTimestamp * 1000)), 1000);
        return () => clearInterval(timerInterval);
    }, []);

    // useEffect(() => {
    //     // if (dateForTimer < Date.now()) {
    //     // }
    //     setDateForTimer(time);
    // }, [time]);
    return (
        <div className="before-yield">
            <h3>The tokens given in yield are acquired on secondary market, from sellers.</h3>
            {timer ? (
                <>
                    <h1 className="timer">{timer}</h1>
                    <h1>before yield initiation</h1>
                </>
            ) : (
                <>
                    <h1>
                        120+ days yield pool <br /> CLAIMING HAS BEEN STARTED
                    </h1>
                </>
            )}
        </div>
    );
};

export default BeforeYieldTimer;
