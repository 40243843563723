import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { projectsFooterNav, setExFooterNavs, socialsFooterNavs } from "./constants";
import footerBg from "../../assets/footer_gradient.png";

interface PropsTypes {}

const Footer = ({}: PropsTypes) => {
    return (
        <>
            <footer className={styles.footer}>
                <img src={footerBg} className={styles.footerBg} alt="Gradient" />
                <div className={styles.footerContent}>
                    <h3 className={styles.footerContentLabel}>Stepex</h3>
                    <div className={styles.footerNav}>
                        <ul className={styles.footerNavList}>
                            <li className={styles.footerNavItem}>
                                <span className={styles.footerNavInfo}>Step ex ››››››››››››››››››››››››</span>
                                <div className={styles.footerNavLinks}>
                                    {setExFooterNavs.map((item, i) =>
                                        item.linkLabel === "Memorandum" ? (
                                            <a
                                                href={item.link}
                                                target="_blank"
                                                className={styles.footerNavLink}
                                                key={item.link}
                                                rel="noreferrer"
                                            >
                                                {item.linkLabel}
                                            </a>
                                        ) : (
                                            <Link to={item.link} className={styles.footerNavLink} key={item.link}>
                                                {item.linkLabel}
                                            </Link>
                                        )
                                    )}
                                </div>
                            </li>
                            <li className={styles.footerNavItem}>
                                <span className={styles.footerNavInfo}>Connect with us ››››››››››››››››</span>
                                <div className={styles.footerNavLinks}>
                                    {socialsFooterNavs.map((item, i) => (
                                        <a
                                            href={item.link}
                                            target="_blank"
                                            className={styles.footerNavLink}
                                            key={item.link}
                                            rel="noreferrer"
                                        >
                                            {item.linkLabel}
                                        </a>
                                    ))}
                                </div>
                            </li>
                            <li className={styles.footerNavItem}>
                                <span className={styles.footerNavInfo}>Projects ›››››››››››››››››››››››</span>
                                <div className={styles.footerNavLinks}>
                                    {projectsFooterNav.map((item, i) => (
                                        <a
                                            href={item.link}
                                            target="_blank"
                                            className={styles.footerNavLink}
                                            key={item.link}
                                            rel="noreferrer"
                                        >
                                            {item.linkLabel}
                                        </a>
                                    ))}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
