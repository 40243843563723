import BN from "bn.js";
import React, { useCallback, useState } from "react";
import ReactCardFlip from "react-card-flip";

import DataContainer from "../../../DataContainer/DataContainer";

import useDimensions from "../../../../hooks/useDimensions";
import { StakeDataType } from "../../../../utils/types";
import "./WithdrawCardBack.scss";

interface WithdrawCardProps {
    maxBPS: number;
    onCancel: () => void;
    stakerData?: StakeDataType;
    penaltyDate: string | undefined;
    cancelUnstake: () => Promise<void>;
    withdrawForce: () => Promise<void>;
    withdraw: () => Promise<void>;
    withdrawTimestamp: string;
    daysPool: number | undefined;
}

const WithdrawUnstaked = ({
    withdrawForce,
    onCancel,
    withdraw,
    withdrawTimestamp,
    penaltyDate,
    stakerData,
    maxBPS,
    cancelUnstake,
    daysPool,
}: WithdrawCardProps) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [isFlippedThird, setIsFlippedThird] = useState(false);

    const { penaltyBP, penaltyDays, stakedTimestamp } = stakerData ?? {};
    const penalty = penaltyBP && maxBPS ? (+penaltyBP / maxBPS) * 100 : 0;
    const date = new Date(+(stakedTimestamp ?? 0) * 1000);

    const { width } = useDimensions();

    const goBack = useCallback(() => {
        setIsFlipped(false);
        setTimeout(() => setIsFlippedThird(false), 200);
    }, []);

    const handleWithdraw = (e: any) => {
        e.stopPropagation();
        withdraw();
    };
    const handleForceWithdraw = (e: any) => {
        e.stopPropagation();
        withdrawForce();
    };

    const allowWithdraw = Math.floor(Date.now() / 1000) > +withdrawTimestamp;
    return (
        <ReactCardFlip isFlipped={isFlipped}>
            <DataContainer className="withdraw-card-black" padding={width > 600 ? 24 : 18}>
                <div className="withdraw-card-black__warning">UNSTAKED</div>
                <div className="withdraw-card__date">
                    {date.toLocaleDateString()}
                    {daysPool ? `: ${daysPool}+ DAYS POOL` : null}
                </div>
                <div className="withdraw-card-black__title">
                    {(stakerData?.amount ?? 0).toLocaleString("en-us")} SPEX
                </div>

                <span className="withdraw-card-black__title">{penaltyDate}</span>
                <span className="withdraw-card-black__description"> {Boolean(penaltyDate) && `UNTIL`} 0% PENALTY</span>

                <div className="withdraw-card-black__actions">
                    <div
                        className="withdraw-card-black__actions__cancel"
                        onClick={() => {
                            setIsFlipped(true);
                        }}
                    >
                        Cancel unstake
                    </div>
                    <div
                        className="withdraw-card-black__actions__agree"
                        onClick={() => {
                            setIsFlippedThird(true);
                            setIsFlipped(true);
                        }}
                    >
                        Withdraw
                    </div>
                </div>
            </DataContainer>
            <>
                {isFlippedThird ? (
                    <DataContainer className="withdraw-card-back" padding={width > 600 ? 24 : 18}>
                        <div className="withdraw-card-back__warning">
                            <span>Warning</span>
                            <span className="back_btn" onClick={goBack}>
                                ←
                            </span>
                        </div>
                        <div className="withdraw-card-back__description">
                            For the early withdrawal (up to {penaltyDays} days) will be charged a fee of {penalty}% of
                            SPEX.
                        </div>

                        <div
                            style={{ justifyContent: allowWithdraw ? "flex-end" : "space-between" }}
                            className="withdraw-card-back__actions"
                        >
                            {allowWithdraw ? null : (
                                <button
                                    type="button"
                                    className="withdraw-card-back__actions__cancel"
                                    onClick={handleForceWithdraw}
                                >
                                    WITHDRAW NOW WITH PENALTY
                                </button>
                            )}

                            <button
                                type="button"
                                disabled={Math.floor(Date.now() / 1000) < +withdrawTimestamp}
                                className="withdraw-card-back__actions__agree"
                                onClick={handleWithdraw}
                            >
                                {Math.floor(Date.now() / 1000) < +withdrawTimestamp
                                    ? `WAIT ${penaltyDate}`
                                    : "WITHDRAW"}
                            </button>
                        </div>
                    </DataContainer>
                ) : (
                    <DataContainer className="withdraw-card-back" padding={width > 600 ? 24 : 18}>
                        <div className="withdraw-card-back__warning">
                            <span>BONUS</span>
                            <span className="back_btn" onClick={goBack}>
                                ←
                            </span>
                        </div>
                        <div style={{ color: "black" }} className="withdraw-card-back__description">
                            After canceling unstake you will remain in the same yield pool that you were in before the
                            unstake.
                        </div>
                        <div className="withdraw-card-back__actions">
                            <div
                                className="withdraw-card-back__actions__agree"
                                onClick={() => {
                                    onCancel();
                                    cancelUnstake().then(() => {
                                        setIsFlipped(false);
                                    });
                                }}
                            >
                                CANCEL UNSTAKE
                            </div>
                        </div>
                    </DataContainer>
                )}
            </>
        </ReactCardFlip>
    );
};

export default WithdrawUnstaked;
