import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { changeNetworkAtMetamask, getShortAddress, NETWORK } from "../../utils/network";

import HeaderAddress from "./supportComponents/HeaderAddress/HeaderAddress";

import useDimensions from "../../hooks/useDimensions";
import "./Header.scss";

import useContextSelector from "../../contexts/contextSelector";

interface HeaderProps {
    currentAddress?: string;
    onConnect: () => void;
    onDisconnect: () => void;
    openHistoryModal: () => void;
    isCorrectChainId?: boolean;
}
const Header = () => {
    // const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);

    // const { setIsHistoryModalVisible } = useHistoryModal();

    const [isScrollToRoadmap, setIsScrollToRoadmap] = useState(false);

    const {
        web3Provider: web3,
        currentAddress,
        onConnect,
        isCorrectChainId,
        onDisconnect,
    } = useContextSelector((state) => state.web3Provider);
    const { setIsHistoryModalVisible } = useContextSelector((state) => state.historyModal);

    useEffect(() => {
        onConnect();
    }, []);

    const openHistoryModal = () => {
        setIsHistoryModalVisible(true);
    };
    // const closeHistoryModal = () => {
    //     setIsHistoryModalVisible(false);
    // };

    const { width } = useDimensions();

    const location = useLocation();
    const navigate = useNavigate();

    const onClickRoadmapHandler = () => {
        if (location.pathname !== "/") {
            navigate({ pathname: "/" });
        }
        setIsScrollToRoadmap(true);
    };

    useEffect(() => {
        if (isScrollToRoadmap && location.pathname === "/") {
            const roadmapContainer = document.getElementById("roadmap-container");
            if (roadmapContainer) {
                const { y } = roadmapContainer.getBoundingClientRect();
                const offsetY = document.documentElement.scrollTop;

                window.scrollTo({ left: 0, top: offsetY + y - 100, behavior: "smooth" });

                setIsScrollToRoadmap(false);
            }
        }
    }, [isScrollToRoadmap, location.pathname]);

    const renderButton = () => {
        if (!currentAddress) {
            return (
                <div className="header__button" onClick={onConnect}>
                    CONNECT WALLET
                </div>
            );
        }
        if (currentAddress) {
            return <HeaderAddress currentAddress={currentAddress} onClick={onDisconnect} />;
        }
        if (currentAddress && !isCorrectChainId) {
            return (
                <div className="header__button--wrong-network" onClick={() => changeNetworkAtMetamask(NETWORK.stepnet)}>
                    WRONG NETWORK
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <div className="header">
                <div className="header__section header__section__navigation-container _desktop">
                    <img className="header__logo" src="/img/logo.svg" />
                    <nav>
                        {/* <Link
                            className={`header__href ${location.pathname === "/" ? " _active" : ""}`}
                            to={{ pathname: "/" }}
                        >
                            Main
                        </Link> */}

                        {/* <button className="header__href" type="button" onClick={onClickRoadmapHandler}>
                            Roadmap
                        </button> */}

                        {/* <Link
                            className={`header__href ${location.pathname === "/zero-inflation" ? " _active" : ""}`}
                            to={{ pathname: "/zero-inflation" }}
                        >
                            Zero-Inflation Yield
                        </Link> */}
                        {/* <Link
                        className={` 
                            header__href 
                            ${
                                location.pathname === `?${GET_POPUP_PARAMS.popup}=${GET_POPUP_TYPE.popup.roadmap}`
                                    ? " _active"
                                    : ""
                            }
                        `}
                        to={`?${GET_POPUP_PARAMS.popup}=${GET_POPUP_TYPE.popup.roadmap}`}
                    >
                        Roadmap
                    </Link> */}
                    </nav>
                </div>

                <div className="header__section _mobile">
                    <img className="header__logo" src="/img/logo.svg" />
                </div>

                <div className="header__connection-container">{renderButton()}</div>
            </div>
            <div className="header__section header__section__navigation-container _mobile">
                <nav>
                    {/* <Link
                        className={`header__href ${location.pathname === "/" ? " _active" : ""}`}
                        to={{ pathname: "/" }}
                    >
                        Main
                    </Link> */}

                    {/* <button className="header__href" type="button" onClick={onClickRoadmapHandler}>
                        Roadmap
                    </button> */}

                    {/* <Link
                        className={`header__href ${location.pathname === "/zero-inflation" ? " _active" : ""}`}
                        to={{ pathname: "/zero-inflation" }}
                    >
                        Zero-Inflation Yield
                    </Link> */}
                    {/* <Link
                className={`
                   header__href
                   ${
                       location.pathname === `?${GET_POPUP_PARAMS.popup}=${GET_POPUP_TYPE.popup.roadmap}`
                           ? " _active"
                           : ""
                   }
               `}
                to={`?${GET_POPUP_PARAMS.popup}=${GET_POPUP_TYPE.popup.roadmap}`}
            >
                Roadmap
            </Link> */}
                </nav>
            </div>
        </>
    );
};

export default Header;
