import Web3 from "web3";

export type NetworkType = "stepnet";

export const NETWORK: Record<NetworkType, NetworkType> = {
    stepnet: "stepnet",
};

export const networkNames = {
    [NETWORK.stepnet]: "Step Network [Mainnet]",
};

const inverse = (obj: Record<any, any>) => Object.fromEntries(Object.entries(obj).map((a) => a.reverse()));

export const idToNetwork: Record<number, NetworkType> = {
    1234: NETWORK.stepnet,
    // 43113: NETWORK.avalanche,
};

export const networkToId = inverse(idToNetwork);

export const networkInfo = {
    // TESTNET
    // [NETWORK.stepnet]: {
    //     chainName: "Stepnet [TESTNET]",
    //     chainId: Web3.utils.toHex(networkToId[NETWORK.stepnet]),
    //     rpcUrls: ["https://rpc.testnet.step.network/"],
    //     blockExplorerUrls: ["https://testnet.stepscan.io/"],
    //     nativeCurrency: {
    //         name: "FITFI",
    //         symbol: "FITFI",
    //         decimals: 18,
    //     },
    // },

    // MAINNET
    [NETWORK.stepnet]: {
        chainName: "Step Network [Mainnet]",
        chainId: Web3.utils.toHex(networkToId[NETWORK.stepnet]),
        rpcUrls: ["https://rpc.step.network/"],
        blockExplorerUrls: ["https://stepscan.io/"],
        nativeCurrency: {
            name: "FITFI",
            symbol: "FITFI",
            decimals: 18,
        },
    },
};

export const changeNetworkAtMetamask = async (networkName: NetworkType) => {
    try {
        await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: Web3.utils.toHex(networkToId[networkName]) }],
        });
    } catch (switchError: any) {
        // This error code indicates that the chain has not been added to MetaMask.

        if (switchError.code === 4902 || switchError.code === -32603) {
            try {
                await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [networkInfo[networkName]],
                });
            } catch (addError) {
                console.error(addError);
            }
        }
    }
};

export const getShortAddress = (address: string | null) =>
    address ? `${address.slice(0, 6)}...${address.slice(address.length - 3)}` : "";
