import React, { useState } from "react";
import { formattedNum } from "../../utils/bigNumber";
// @ts-ignore
import "./styles.modules.scss";

type Props = {
    spexData: any;
};

const SpexInfo = ({ spexData }: Props) => {
    const [time, setTime] = useState<number>(3);
    const getPrefix = () => {
        if (time === 1) return "day";
        if (time === 2) return "weekly";
        return "total";
    };

    return (
        <div className="info_row">
            <div className="info_value">
                <p>
                    BURNED SPEX: <span>{formattedNum(spexData[`${getPrefix()}Burned`])}</span>
                </p>
                <p>
                    TREASURY POOL: <span>{formattedNum(spexData[`${getPrefix()}TreasuryPool`])}</span>
                </p>
            </div>
            <div className="info_button_row">
                <button
                    type="button"
                    onClick={() => setTime(1)}
                    className={`info_button ${time === 1 ? "info_active" : ""}`}
                >
                    24H
                </button>
                <button
                    type="button"
                    onClick={() => setTime(2)}
                    className={`info_button ${time === 2 ? "info_active" : ""}`}
                >
                    WEEK
                </button>
                <button
                    type="button"
                    onClick={() => setTime(3)}
                    className={`info_button ${time === 3 ? "info_active" : ""}`}
                >
                    TOTAL
                </button>
            </div>
        </div>
    );
};

export default SpexInfo;
