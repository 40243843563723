import React, { useCallback, useEffect, useState } from "react";
import Web3 from "web3";
import Web3Modal, { IProviderOptions } from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";

import Header from "../Header/Header";
import { ArrowIcon } from "../../assets/svgComponents";
import DataContainer from "../DataContainer/DataContainer";
import useDimensions from "../../hooks/useDimensions";
import { changeNetworkAtMetamask, NETWORK, networkInfo, networkToId } from "../../utils/network";

import "./ZeroInflationPage.scss";
import PoolsComponent from "./components/PoolsComponent";
import BeforeYieldTimer from "./components/BeforeYieldTimer";
import StakeHistory from "./components/StakeHistory";
import useContextSelector from "../../contexts/contextSelector";
import StakingABI from "../../constants/StepExStaking.json";
import address from "../../constants/address";
import { IHistoryData, IPoolData, IPoolResponse } from "./constants/types";
import { poolsDuration, secondsInDays, stakesMockData } from "./constants/constants";
import { STAKING_API_ENDPOINT } from "../../utils/api";
import Footer from "../Footer";

const stakingSCAddress = address.STEP_EX_STAKING;

const defaultPoolsData = [
    {
        totalPool: "",
        poolPerMonth: "",
        apr: "",
        poolDuration: poolsDuration.slice(1, 4)[0],
        userTokens: "",
        userRewards: null,
    },
    {
        totalPool: "",
        poolPerMonth: "",
        apr: "",
        poolDuration: poolsDuration.slice(1, 4)[1],
        userTokens: "",
        userRewards: null,
    },
    {
        totalPool: "",
        poolPerMonth: "",
        apr: "",
        poolDuration: poolsDuration.slice(1, 4)[2],
        userTokens: "",
        userRewards: null,
    },
];

const ZeroInflationPage = () => {
    const API_URL = STAKING_API_ENDPOINT;

    const [poolsIsLoading, setPoolsIsLoading] = useState(false);
    const [poolsData, setPoolsData] = useState<IPoolData[]>(defaultPoolsData);

    // const [rewardsIsLoading, setRewardsIsLoading] = useState(false);
    // const [rewardsCount, setRewardsCount] = useState<number | null>(null);

    const [poolsDataResponse, setPoolsDataResponse] = useState<IPoolResponse[] | IPoolData[]>(defaultPoolsData);
    const [historyData, setHistoryData] = useState<IHistoryData[][]>([]);

    const { web3Provider: web3, currentAddress, onConnect } = useContextSelector((state) => state.web3Provider);
    // const { web3Provider: web3, onConnect } = useContextSelector((state) => state.web3Provider);
    // const currentAddress = "0x850A1485BCEDA758364b98B8fca0042604108E2f";

    const sortHistoryHelper = (itemADate: IHistoryData, itemBDate: IHistoryData) => {
        return itemBDate.stakedTimestamp.valueOf() - itemADate.stakedTimestamp.valueOf();
    };

    const historyConfigureDataHelper = useCallback(
        async (poolsList: IPoolResponse[] | IPoolData[], stakes: any[] | null) => {
            const ledger: IHistoryData[][] = [];
            const stakesFilterHelper = (item: any) => {
                const ledgerIndexItem = ledger
                    .flat(Infinity)
                    .findIndex((ledgItem: any) => ledgItem.stakedTimestamp / 1000 === +item.stakedTimestamp);

                return ledgerIndexItem === -1;
            };

            for (let i = 0; i <= poolsList.length; i++) {
                const pool: IHistoryData[] = [];

                stakes?.filter(stakesFilterHelper).forEach((item: any) => {
                    if (
                        Math.floor(+new Date() / 1000) - Number(item.stakedTimestamp) >
                        secondsInDays * [...poolsDuration.slice(0, poolsList.length + 1)].reverse()[i]
                    ) {
                        const historyItem: IHistoryData = {
                            amount: item?.amount && Number(item.amount) > 0 ? Number(item.amount) / 10 ** 18 : 0,
                            penaltyBP: item?.penaltyBP ? item.penaltyBP : "",
                            penaltyDays: item?.penaltyDays ? Number(item.penaltyDays) : 0,
                            shares: item?.shares ? item.shares : "",
                            stakedTimestamp: new Date(Number(item.stakedTimestamp) * 1000),
                            currentPool: [...poolsDuration.slice(0, poolsList.length + 1)].reverse()[i],
                        };
                        pool.push(historyItem);
                    }
                });

                ledger.push([...pool].sort(sortHistoryHelper));
            }
            setHistoryData([...ledger.reverse()]);
        },
        []
    );

    const poolsConfigureDataHelper = useCallback(
        async (poolsList: IPoolResponse[] | IPoolData[] | null, stakes: any[] | null) => {
            if (!poolsList) {
                return;
            }

            const poolsConfigureData: IPoolData[] = [];

            for (let i = 0; i < poolsList.length; i++) {
                const pool: IHistoryData[] = [];

                stakes?.forEach((item: any) => {
                    if (
                        Math.floor(+new Date() / 1000) - Number(item.stakedTimestamp) >
                        secondsInDays * poolsDuration.slice(1, poolsList.length + 1)[i]
                    ) {
                        const historyItem: IHistoryData = {
                            amount: item?.amount && Number(item.amount) > 0 ? Number(item.amount) / 10 ** 18 : 0,
                            penaltyBP: item?.penaltyBP ? item.penaltyBP : "",
                            penaltyDays: item?.penaltyDays ? Number(item.penaltyDays) : 0,
                            shares: item?.shares ? item.shares : "",
                            stakedTimestamp: new Date(Number(item.stakedTimestamp) * 1000),
                            currentPool: poolsDuration.slice(0, poolsList.length + 1)[i],
                        };
                        pool.push(historyItem);
                    }
                });

                const amount = pool.length
                    ? pool.reduce((prev, current) => Number(prev) + Number(current.amount), 0)
                    : 0;

                const poolData: IPoolData = {
                    totalPool: poolsList[i].totalPool,
                    poolPerMonth: poolsList[i].poolPerMonth,
                    apr: poolsList[i].apr,
                    poolDuration: poolsDuration.slice(1, poolsList.length + 1)[i],
                    userTokens: amount > 0 ? amount.toLocaleString("en") : amount.toLocaleString("en"),
                    userRewards: null,
                };

                poolsConfigureData.push(poolData);
            }

            setPoolsData(poolsConfigureData);
        },
        []
    );

    const getTotalStakes = useCallback(async (): Promise<any[] | null> => {
        try {
            if (!currentAddress) {
                return null;
            }

            const stakes = [];

            const contract = new web3.eth.Contract(StakingABI as any, stakingSCAddress);
            const stakeCount = await contract.methods.stakerStakeCount(currentAddress).call();

            for (let i = 0; i < Number(stakeCount); i++) {
                const stake = await contract.methods.stakers(currentAddress, i).call();
                if (stake.unstaked === false) {
                    stakes.push(stake);
                }
            }
            // return stakesMockData;
            return stakes;
        } catch (error) {
            console.error(error);
            return null;
        }
    }, [currentAddress, web3]);

    const getPoolsData = useCallback(async () => {
        try {
            setPoolsIsLoading(true);
            const response: AxiosResponse<IPoolResponse[], any> = await axios.get(`${API_URL}chains/pools`);
            if (response.status < 300) {
                setPoolsDataResponse(response.data);
            } else {
                setPoolsDataResponse(defaultPoolsData);
            }
        } catch (error) {
            console.error(error);
            setPoolsDataResponse(defaultPoolsData);
        } finally {
            setPoolsIsLoading(false);
        }
    }, []);

    // const getRewardsByUser = useCallback(async () => {
    //     try {
    //         setRewardsIsLoading(true);
    //         const response: AxiosResponse<{ address: string; reward: string }, any> = await axios.get(
    //             `${API_URL}users/reward/${currentAddress}`
    //         );
    //         if (response.status < 300) {
    //             const rewards = +response.data.reward;
    //             setRewardsCount(Number.isNaN(rewards) ? null : rewards);
    //             setRewardsIsLoading(false);
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }, [currentAddress]);

    const loadInfoHelper = useCallback(async () => {
        try {
            const stakes = await getTotalStakes();
            poolsConfigureDataHelper(poolsDataResponse, stakes);
            historyConfigureDataHelper(poolsDataResponse, stakes);
        } catch (error) {
            console.error(error);
        }
    }, [getTotalStakes, poolsConfigureDataHelper, historyConfigureDataHelper, poolsDataResponse]);

    useEffect(() => {
        loadInfoHelper();
    }, [loadInfoHelper]);

    useEffect(() => {
        getPoolsData();
    }, []);

    // useEffect(() => {
    //     getRewardsByUser();
    // }, [getRewardsByUser]);

    return (
        <div className="zero-inflation__container">
            <BeforeYieldTimer />
            <PoolsComponent poolsData={poolsData} isLoading={poolsIsLoading} />
            <StakeHistory historyData={historyData} />
            <Footer />
        </div>
    );
};

export default ZeroInflationPage;
