import React, { FC } from "react";

import Header from "../Header/Header";

import "./Layout.scss";
import { showDisclaimer } from "../../constants/texts";

const Layout: FC = (props) => {
    const { children } = props;

    return (
        <>
            <div className={showDisclaimer ? "header-wrapper-disclaimer" : "header-wrapper"}>
                <Header />
            </div>
            {children}
        </>
    );
};

export default Layout;
