import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";

export const client = new ApolloClient({
    link: new HttpLink({
        uri: "https://graph.step.network/subgraphs/name/stepapp/stepex",
    }),
    cache: new InMemoryCache(),
});

export const spexClient = new ApolloClient({
    link: new HttpLink({
        uri: "https://graph.step.network/subgraphs/name/stepapp/spex",
    }),
    cache: new InMemoryCache(),
});

export const blockClient = new ApolloClient({
    link: new HttpLink({
        uri: "https://graph.step.network/subgraphs/name/blocklytics/ethereum-blocks",
    }),
    cache: new InMemoryCache(),
});
