import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, HashRouter, Routes, Route, Navigate } from "react-router-dom";
import { ApolloProvider } from "react-apollo";

import App from "./components/App/App";
import Disclaimer from "./components/disclaimer";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import Layout from "./components/Layout/Layout";
import GetParameterPopups from "./components/PopupManager/PopupManager";
import ZeroInflationPage from "./components/ZeroInflationPage/ZeroInflationPage";
import { showDisclaimer } from "./constants/texts";
import { HistoryModalContextProvider } from "./contexts/HistoryModalContext";
import { RootContextProvider } from "./contexts/RootContext";
import { client } from "./apollo/client";

import "./index.scss";

ReactDOM.render(
    <React.StrictMode>
        <HashRouter>
            <ErrorBoundary>
                <ApolloProvider client={client}>
                    <RootContextProvider>
                        {showDisclaimer ? <Disclaimer /> : null}
                        <Layout>
                            <Routes>
                                <Route path="/" element={<App />} />
                                <Route path="/zero-inflation" element={<ZeroInflationPage />} />
                                <Route path="*" element={<Navigate to="/" replace />} />
                            </Routes>
                        </Layout>
                    </RootContextProvider>
                </ApolloProvider>

                {/* <GetParameterPopups /> */}
            </ErrorBoundary>
        </HashRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
