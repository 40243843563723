import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Contract } from "web3-eth-contract";

import classNames from "classnames";
import WithdrawCardContainer from "../WithdrawCardContainer/WithdrawCardContainer";
import { StakeDataType } from "../../../../utils/types";

import "./WithdrawModal.scss";
import { showDisclaimer } from "../../../../constants/texts";

interface WithdrawModalContainerProps {
    visible: boolean;
    currentAddress?: string;
    decimals: number;
    onClose: () => void;
    onUpdate: () => void;
    stakers: StakeDataType[];
    stepContract?: Contract;
    epochIndex?: number;
}

const WithdrawModal = ({
    stakers,
    visible,
    onClose,
    onUpdate,
    stepContract,
    currentAddress,
    decimals,
    epochIndex,
}: WithdrawModalContainerProps) => {
    const [maxBPS, setMaxBPS] = useState(0);

    useEffect(() => {
        stepContract?.methods
            .MAX_BPS()
            .call()
            .then((v: number) => {
                setMaxBPS(v);
            });
    }, [stepContract]);

    const handleWithdraw = (id: number) => async () => {
        const tx = await stepContract?.methods.unstake(id).send({ from: currentAddress });
        onUpdate();
    };
    const cancelUnstake = (id: number) => async () => {
        const tx = await stepContract?.methods.cancelUnstake(id).send({ from: currentAddress });
        onUpdate();
    };

    const withdraw = (id: number, force: boolean) => async () => {
        const tx = await stepContract?.methods.withdraw(id, force).send({ from: currentAddress });
        onUpdate();
    };

    return (
        <Modal
            isOpen={visible}
            onRequestClose={onClose}
            className="withdraw-modal-container"
            overlayClassName="withdraw-modal-container__overlay"
        >
            <div className="withdraw-modal">
                <div
                    className={classNames("withdraw-modal__title", {
                        "withdraw-modal__titleWithDisclaimer": showDisclaimer,
                    })}
                >
                    WITHDRAW
                </div>
                <div className="withdraw-modal__cards">
                    {stakers.map((stakerData, i) => (
                        <WithdrawCardContainer
                            epochIndex={epochIndex}
                            decimals={decimals}
                            maxBPS={maxBPS}
                            key={stakerData.id}
                            stakerData={stakerData}
                            onWithdraw={handleWithdraw(stakerData.id)}
                            withdrawForce={withdraw(stakerData.id, true)}
                            withdraw={withdraw(stakerData.id, false)}
                            cancelUnstake={cancelUnstake(stakerData.id)}
                        />
                    ))}
                </div>
            </div>
            <div
                className={classNames("withdraw-modal__close", {
                    "withdraw-modal__activeDisclaimer": showDisclaimer,
                })}
                onClick={onClose}
            >
                CLOSE
            </div>
            <div className="withdraw-modal-space" onClick={onClose} />
        </Modal>
    );
};

export default WithdrawModal;
