export default {
    // TESTNET
    // MOCK_TOKEN: "0x56768D2DD02D64094d7BA2f0D8e47FeF65cc6de6",
    // STEP_EX_STAKING: "0xA13F939Af56E79ABf81A07b256788629484e9e22",
    // STEP_EX_CALCULATOR: "0x77860967c8Cb7B82D85E5bF30ef767Bc75A756CB",
    // STEP_EX_DISTRIBUTOR: "0x825198df206dDAc01Bb814549dFC961096394453",
    // MERKLE_DISTRIBUTOR1: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR2: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR3: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR4: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR5: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR6: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR7: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR8: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR9: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",

    // MAINNET
    MOCK_TOKEN: "0x7db4072D6e26BBF35129E826d656f230F791CD2f",
    STEP_EX_STAKING: "0x1D87e2211F4939246F38b77f25a7E77DB22B112E",
    STEP_EX_CALCULATOR: "0xacf7F770d742526eE03C2aAdf67C91050E4A954A",
    STEP_EX_DISTRIBUTOR: "0xdB2D6da1be1afD2Ce1b759F2B61F53968f512f82",
    MERKLE_DISTRIBUTOR1: "0xCE36884C6E0dEF866F22bBf00CeE4D3919979B56",
    MERKLE_DISTRIBUTOR2: "0x3990da5B8503aA34AbFA3fd10f5a683B9121eA44",
    MERKLE_DISTRIBUTOR3: "0xFFAB22e8918229a594CB0096e42D8cF419024605",
    MERKLE_DISTRIBUTOR4: "0x250Ac0D93c574fC1482292fB282c5ffB2b11b784",
    MERKLE_DISTRIBUTOR5: "0x52Af7aB8a106BBF0F1b409Ac502D68acAC5312f5",
    MERKLE_DISTRIBUTOR6: "0x43aEF4d17E1C3e0F260d374e28988238d0bEAD39",
    MERKLE_DISTRIBUTOR7: "0x33BADb36508ca76A904Dc52525D6315491927B6f",
    MERKLE_DISTRIBUTOR8: "0xbf0D27ec57Ae2a1cd15556bB21acD71b781122c8",
    MERKLE_DISTRIBUTOR9: "0xB287A77eb9450683176600F151e05E2Ccf5C053F",
    MULTICALL: "0x176CcFFbAB792Aaa0da7C430FE20a7106d969f66",
};
