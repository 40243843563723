import { useEffect, useState, useRef, useCallback } from "react";
import { ethers } from "ethers";
import dayjs from "dayjs";
import Web3 from "web3";
import { ETH_PRICE, GET_BLOCK, SPEX_BURNED, SPEX_POL, STEPEX_FEES, TOKEN_DATA } from "../apollo/queries";
import { blockClient, client, spexClient } from "../apollo/client";

export const SPEX_ADDRESS = "0x7db4072D6e26BBF35129E826d656f230F791CD2f";

const circulationSupply = 120_000_000;

export const useSPEXInfo = () => {
    const [spexData, setSpexData] = useState<any>();
    const tokenData = useTokenData(SPEX_ADDRESS.toLowerCase());

    const fetch = useCallback(async () => {
        const { weekly: weeklyBurned, total: totalBurned, day: dayBurned } = await getSpexBurned();
        const stepExFees = await getStepExFees();

        const weeklyFeesCollected = stepExFees.weekly * 0.003;
        const dayFeesCollected = stepExFees.day * 0.003;
        const totalFeesCollected = stepExFees.total * 0.003;

        const weeklyTreasuryPool = weeklyBurned * 2;
        const dayTreasuryPool = dayBurned * 2;
        const totalTreasuryPool = totalBurned * 2;

        const SpexPol = await getSpexPolIncome();

        const marketCup = +tokenData.priceUSD * +circulationSupply;

        setSpexData({
            totalStepexFees: totalFeesCollected,
            dayStepexFees: dayFeesCollected,
            weeklyFees: weeklyFeesCollected,
            weeklyBurned,
            weeklyTreasuryPool,
            totalTreasuryPool,
            totalBurned,
            dayBurned,
            SpexPol,
            marketCup,
            dayTreasuryPool,
            price: tokenData.priceUSD,
        });
    }, [tokenData]);

    useEffect(() => {
        if (!spexData && tokenData.priceUSD) {
            fetch();
        }
    }, [tokenData]);

    return spexData;
};

const getSpexBurned = async () => {
    const total = await spexClient.query({
        query: SPEX_BURNED,
        fetchPolicy: "cache-first",
    });

    return {
        weekly: total.data.tokenDailySnapshots
            .slice(total.data.tokenDailySnapshots?.length - 7, total.data.tokenDailySnapshots?.length)
            .reduce((sum: any, current: any) => reduceEther(sum, current.dailyBurnAmount), 0),
        total: total.data.tokenDailySnapshots.reduce(
            (sum: any, current: any) => reduceEther(sum, current.dailyBurnAmount),
            0
        ),
        day: reduceEther(0, total.data.tokenDailySnapshots.at(-1).dailyBurnAmount),
    };
};

const getStepExFees = async () => {
    const total = await client.query({
        query: STEPEX_FEES,
        fetchPolicy: "cache-first",
    });
    return {
        weekly: total.data.stepExDayDatas
            .slice(total.data.stepExDayDatas?.length - 7, total.data.stepExDayDatas?.length)
            .reduce((sum: any, current: any) => sum + +current.dailyVolumeUSD, 0),
        day: total.data.stepExDayDatas[total.data.stepExDayDatas?.length - 1].dailyVolumeUSD,
        total: total.data.stepExDayDatas.reduce((sum: any, current: any) => sum + +current.dailyVolumeUSD, 0),
    };
};

const getSpexPolIncome = async () => {
    const result = await client.query({
        query: SPEX_POL,
        fetchPolicy: "cache-first",
    });
    return result.data.pairs[0].volumeUSD * 0.003;
};

export function useDebounce(callback: any, delay: any) {
    const timer: any = useRef();

    const debouncedCallback = useCallback(
        (...args: any) => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
            timer.current = setTimeout(() => {
                callback(...args);
            }, delay);
        },
        [callback, delay]
    );

    return debouncedCallback;
}

export function useTokenData(tokenAddress: string): any {
    const [tokenData, setTokenData] = useState();
    const [ethPrice, ethPriceOld] = useEthPrice();

    useEffect(() => {
        if (!tokenData && ethPrice && ethPriceOld && isAddress(tokenAddress)) {
            getTokenData(tokenAddress, ethPrice, ethPriceOld).then((data) => {
                setTokenData(data);
            });
        }
    }, [ethPrice, ethPriceOld, tokenAddress, tokenData]);

    return tokenData || {};
}

export function useEthPrice() {
    const [ethData, setEthData] = useState<any>();

    useEffect(() => {
        async function checkForEthPrice() {
            if (!ethData) {
                const [newPrice, oneDayPrice, priceChange] = await getEthPrice();
                setEthData({ newPrice, oneDayPrice, priceChange });
            }
        }
        checkForEthPrice();
    }, [ethData]);

    return [ethData?.newPrice, ethData?.oneDayPrice];
}

const getEthPrice = async () => {
    const utcCurrentTime = dayjs();
    const utcOneDayBack = utcCurrentTime.subtract(1, "day").startOf("minute").unix();

    let ethPrice = 0;
    let ethPriceOneDay = 0;
    let priceChangeETH = 0;

    try {
        const oneDayBlock = await getBlockFromTimestamp(utcOneDayBack);
        const result = await client.query({
            query: ETH_PRICE(),
            fetchPolicy: "cache-first",
        });

        const resultOneDay = await client.query({
            query: ETH_PRICE(oneDayBlock),
            fetchPolicy: "cache-first",
        });
        const currentPrice = result?.data?.bundles[0]?.fitfiPrice;
        const oneDayBackPrice = resultOneDay?.data?.bundles[0]?.fitfiPrice;
        priceChangeETH = getPercentChange(currentPrice, oneDayBackPrice);
        ethPrice = currentPrice;
        ethPriceOneDay = oneDayBackPrice;
    } catch (e) {
        console.log(e);
    }

    return [ethPrice, ethPriceOneDay, priceChangeETH];
};

export async function getBlockFromTimestamp(timestamp: number) {
    const result = await blockClient.query({
        query: GET_BLOCK,
        variables: {
            timestampFrom: timestamp,
            timestampTo: timestamp + 600,
        },
        fetchPolicy: "cache-first",
    });
    return result?.data?.blocks?.[0]?.number;
}

export const getPercentChange = (valueNow: any, value24HoursAgo: any) => {
    const adjustedPercentChange =
        ((parseFloat(valueNow) - parseFloat(value24HoursAgo)) / parseFloat(value24HoursAgo)) * 100;
    if (Number.isNaN(adjustedPercentChange) || !Number.isFinite(adjustedPercentChange)) {
        return 0;
    }
    return adjustedPercentChange;
};

export const isAddress = (value: string) => {
    try {
        return ethers.utils.isAddress(value.toLowerCase());
    } catch {
        return false;
    }
};

const getTokenData = async (address: string, ethPrice: any, ethPriceOld: any) => {
    const utcCurrentTime = dayjs();
    const utcOneDayBack = utcCurrentTime.subtract(1, "day").startOf("minute").unix();
    const utcTwoDaysBack = utcCurrentTime.subtract(2, "day").startOf("minute").unix();
    const oneDayBlock = await getBlockFromTimestamp(utcOneDayBack);
    const twoDayBlock = await getBlockFromTimestamp(utcTwoDaysBack);

    // initialize data arrays
    let data: any = {};
    let oneDayData: any = {};
    let twoDayData: any = {};

    try {
        // fetch all current and historical data
        const result = await client.query({
            query: TOKEN_DATA(address),
            fetchPolicy: "cache-first",
        });
        data = result?.data?.tokens?.[0];

        // get results from 24 hours in past
        const oneDayResult = await client.query({
            query: TOKEN_DATA(address, oneDayBlock),
            fetchPolicy: "cache-first",
        });
        oneDayData = { ...oneDayResult.data.tokens[0] };

        // get results from 48 hours in past
        const twoDayResult = await client.query({
            query: TOKEN_DATA(address, twoDayBlock),
            fetchPolicy: "cache-first",
        });
        twoDayData = { ...twoDayResult.data.tokens[0] };

        // catch the case where token wasnt in top list in previous days
        if (!oneDayData) {
            const oneDayResult2 = await client.query({
                query: TOKEN_DATA(address, oneDayBlock),
                fetchPolicy: "cache-first",
            });
            oneDayData = { ...oneDayResult2.data.tokens[0] };
        }
        if (!twoDayData) {
            const twoDayResult2 = await client.query({
                query: TOKEN_DATA(address, twoDayBlock),
                fetchPolicy: "cache-first",
            });
            twoDayData = { ...twoDayResult2.data.tokens[0] };
        }

        // calculate percentage changes and daily changes
        const [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
            data.tradeVolumeUSD,
            oneDayData?.tradeVolumeUSD ?? 0,
            twoDayData?.tradeVolumeUSD ?? 0
        );

        // calculate percentage changes and daily changes
        const [oneDayVolumeUT, volumeChangeUT] = get2DayPercentChange(
            data.untrackedVolumeUSD,
            oneDayData?.untrackedVolumeUSD ?? 0,
            twoDayData?.untrackedVolumeUSD ?? 0
        );

        // calculate percentage changes and daily changes
        const [oneDayTxns, txnChange] = get2DayPercentChange(
            data.txCount,
            oneDayData?.txCount ?? 0,
            twoDayData?.txCount ?? 0
        );

        const priceChangeUSD = getPercentChange(
            data?.derivedFITFI * ethPrice,
            parseFloat(oneDayData?.derivedFITFI ?? 0) * ethPriceOld
        );

        const currentLiquidityUSD = data?.totalLiquidity * ethPrice * data?.derivedFITFI;
        const oldLiquidityUSD = oneDayData?.totalLiquidity * ethPriceOld * oneDayData?.derivedFITFI;

        // set data
        data.priceUSD = data?.derivedFITFI * ethPrice;
        data.totalLiquidityUSD = currentLiquidityUSD;
        data.oneDayVolumeUSD = oneDayVolumeUSD;
        data.volumeChangeUSD = volumeChangeUSD;
        data.priceChangeUSD = priceChangeUSD;
        data.oneDayVolumeUT = oneDayVolumeUT;
        data.volumeChangeUT = volumeChangeUT;
        const liquidityChangeUSD = getPercentChange(currentLiquidityUSD ?? 0, oldLiquidityUSD ?? 0);
        data.liquidityChangeUSD = liquidityChangeUSD;
        data.oneDayTxns = oneDayTxns;
        data.txnChange = txnChange;

        // used for custom adjustments
        data.oneDayData = oneDayData?.[address];
        data.twoDayData = twoDayData?.[address];

        // new tokens
        if (!oneDayData && data) {
            data.oneDayVolumeUSD = data.tradeVolumeUSD;
            data.oneDayVolumeETH = data.tradeVolume * data.derivedFITFI;
            data.oneDayTxns = data.txCount;
        }
    } catch (e) {
        console.log(e);
    }
    return data;
};

export const get2DayPercentChange = (valueNow: any, value24HoursAgo: any, value48HoursAgo: any) => {
    // get volume info for both 24 hour periods
    const currentChange = parseFloat(valueNow) - parseFloat(value24HoursAgo);
    const previousChange = parseFloat(value24HoursAgo) - parseFloat(value48HoursAgo);

    const adjustedPercentChange =
        (parseFloat(String(currentChange - previousChange)) / parseFloat(String(previousChange))) * 100;

    if (Number.isNaN(adjustedPercentChange) || !Number.isFinite(adjustedPercentChange)) {
        return [currentChange, 0];
    }
    return [currentChange, adjustedPercentChange];
};

export const reduceEther = (num1: any, num2: any) => {
    return +num1 + +ethers.utils.formatEther(num2);
};
