import ADDRESSES from "./address";

export const winnersUrls = [
    "https://drive.google.com/file/d/1VXDoHSKghMwqI_8iAba3nipjnDM-NSHT/view",
    "https://drive.google.com/file/d/1N62C-yqz2GTDL_kECPUnkUWk9WupAY99/view?usp=sharing",
    "https://drive.google.com/file/d/1aM_ubujpS52WJ1_G7x2EZkdmAOsGf6KH/view?usp=sharing",
    "https://drive.google.com/file/d/1FGmFiFjZ4L7Mz9X0_NjZbrIlBohfUOE2/view?usp=sharing",
    "https://drive.google.com/file/d/1NNixq53hvRsWPlvLPMr5yjA8dp4x6CkK/view?usp=sharing",
    "https://drive.google.com/file/d/1u8pzmbwvJ8Ol6RbaqA5USu28qmMM2M86/view?usp=sharing",
    "https://drive.google.com/file/d/14mqRuGEjD9q8oXkRk3h5BVGqQoUrmJIP/view?usp=sharing",
    "https://drive.google.com/file/d/1jDZkolsOzKjJ0KnGOWoURMUfKkTS5nGU/view?usp=sharing",
    "https://drive.google.com/file/d/1Vs-t64vPPeo4VPpl4xIdqTRrOenZFn6T/view?usp=sharing",
];

export const snapshotDates = [
    "20 MAY 12:00 UTC",
    "3 JUNE 12:00 UTC",
    "17 JUNE 12:00 UTC",
    "1 JULY 12:00 UTC",
    "15 JULY 12:00 UTC",
    "29 JULY 12:00 UTC",
    "12 AUGUST 12:00 UTC",
    "26 AUGUST 12:00 UTC",
    "9 SEPTEMBER 12:00 UTC",
];
export const contractAddresses = [
    ADDRESSES.MERKLE_DISTRIBUTOR1,
    ADDRESSES.MERKLE_DISTRIBUTOR2,
    ADDRESSES.MERKLE_DISTRIBUTOR3,
    ADDRESSES.MERKLE_DISTRIBUTOR4,
    ADDRESSES.MERKLE_DISTRIBUTOR5,
    ADDRESSES.MERKLE_DISTRIBUTOR6,
    ADDRESSES.MERKLE_DISTRIBUTOR7,
    ADDRESSES.MERKLE_DISTRIBUTOR8,
    ADDRESSES.MERKLE_DISTRIBUTOR9,
];
export const lotteryHeldDate = "September 12";

// 1st october & 8th october, disabled all buttons
export const isTechnicalWorks = false;
// 8 oct & 16 oct show green background
export const successTechnicalWorks = false;
// to 16th October show global disclaimer
export const showDisclaimer = false;

// export const isTechnicalWorks = Date.now() > 1664625600000 && Date.now() < 1665230400000;// 1st october & 8th october
// export const successTechnicalWorks = Date.now() > 1665230400000 && Date.now() < 1665921600000; // 8 oct & 16 oct
// export const showDisclaimer = Date.now() < 1665921600000; // 16th October
